
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Link } from 'react-router-dom';
import { Button, TextField, } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import swal from "sweetalert";
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}
function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
const Reviewer = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setloading] = useState(false);
  const [userlist, setUserlist] = useState([]);
  const [permissions, setrPermissions] = useState([]);
  const eventid = localStorage.getItem('eventid');

  useEffect(() => {
    axios.get(`api/getpermissionByRole`).then(res => {

      if (res.status === 200) {
        const datad = res.data.data.permissions;
        setrPermissions(datad)
      } else {

      }

    })

  }, []);


  useEffect(() => {
    axios.get(`api/alluser?id=${eventid}`).then(res => {
      if (res.status === 200) {

        setUserlist(res.data.user);

      }
      else {
        setloading(false);
      }


    });

  }, []);



  const [searchTerm, setSearchTerm] = useState('');
  const filteredPosts = userlist.filter((row) =>
    //(row.phone && row.phone.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (row.role && row.role.toLowerCase().includes(searchTerm.toLowerCase()))
    // Add more fields with null checks as needed
  );

  const totalRecords = userlist.length;
  const filteredRecordsCount = filteredPosts.length;

  const deleteUser = (e, id) => {
    e.preventDefault();
    swal('warning', "You Don't have Access To Delete Any User", 'warning');
    // const thisClicked = e.currentTarget;
    // thisClicked.innerText = "Deleting...";

    // axios.get(`api/user-delete/${id}`).then(res => {

    //   if (res.data.status === 200) {

    //     swal('Success', res.data.message, 'success');
    //     thisClicked.closest("tr").remove();

    //   }
    //   else if (res.data.status === 404) {

    //     swal('Success', res.data.message, 'success');
    //     thisClicked.innerText = "Delete";
    //   }

    // });

  }


  // console.log(singleReviewer.userevent.eventname)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredPosts.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
      <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>


        <Card sx={{ width: '82vw', float: 'center' }} >

          <CardContent >

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography component="h1" variant="h5">
                User Management
              </Typography>
              Total Records: {totalRecords} | Filtered Records: {filteredRecordsCount}
              <Grid item xs={8} >
                <TextField
                  fullWidth
                  label="Filter By: Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid>

            </div>
            <h4 sx={{ m: 2, color: '#000000' }}>

              {(() => {
                if (permissions['p_user-create'] === true) {
                  return (

                    <Button sx={{
                      float: 'right',

                      marginTop: '2%',
                      ":hover": {
                        color: "white"
                      }
                    }}
                      color='primary'
                      size='small'
                      variant='contained'
                      component={Link}
                      to="/admin/adduser">
                      Add User
                    </Button>

                  )
                }
                else {
                  return (
                    <div> </div>
                  )
                }

              })
                ()}
            </h4>

            <Box component="form" noValidate sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow sx={{ color: '#FFFFFF' }}>
                      <TableCell > #</TableCell>
                      <TableCell align="center"> Name</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Role</TableCell>
                      <TableCell align="center" style={{ width: 260 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {(rowsPerPage > 0
                      ? filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : filteredPosts
                    ).map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" style={{ width: 60 }}>
                          {i + 1}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                          {row.name}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                          {row.email}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">
                          {row.role || ""}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="right">


                          {
                            permissions['p_user-edit'] === true ?
                              <Button sx={{
                                margin: 1,

                                ":hover": {
                                  color: "white"
                                },
                                backgroundColor: '#145348',
                              }}
                                color='primary'
                                size='small'
                                variant='contained'
                                component={Link}
                                to={`/admin/edituser/${row.id}`} >
                                <EditNoteIcon />
                              </Button> : ""}
                          {
                            permissions['p_user-delete'] === true ?
                              <Button sx={{
                                margin: 1,

                                ":hover": {
                                  color: "white"
                                },
                                backgroundColor: '#145348',
                              }}
                                color='primary'
                                size='small'
                                variant='contained' onClick={(e) => deleteUser(e, row.id)}>
                                <DeleteIcon />
                              </Button> : ""}
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={filteredPosts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        </Card>
      </Box>


    </div>
  );
}

export default Reviewer;
