
import Dashbord from '../components/Admin/Dashbord';
import Events from '../components/Admin/Events/AllEvents';
import AllUsers from '../components/Admin/Users/AllUsers';
import AllRoles from '../components/Admin/Role/AllRoles';
import AddUser from '../components/Admin/Users/AddUser';
import AddSpeakser from '../components/Admin/Speakers/AddSpeakser';
import AddRole from '../components/Admin/Role/AddRole';
import EidtUser from '../components/Admin/Users/EidtUser';
import Permissions from '../components/Admin/permissions/Permissions';

// Abstract Routes
import AbstractDashbord from '../components/Admin/Abstract/AbstractDashbord';
import AddCategory from '../components/Admin/Abstract/Category/AddCategory';
import AllCategory from '../components/Admin/Abstract/Category/AllCategory';
//abstract Submisssion
import AddSubmissionType from '../components/Admin/Abstract/SubmissionType/AddSubmissionType';
import IndexSubmissionType from '../components/Admin/Abstract/SubmissionType/IndexSubmissionType';
//Article
import ApproveArticle from '../components/Admin/Abstract/Article/ApproveArticle';
import AddApproveArticle from '../components/Admin/Abstract/Article/AddApproveArticle';
import EditApproveArticle from '../components/Admin/Abstract/Article/EditApproveArticle';
import PendingList from '../components/Admin/Abstract/Article/PendingList';
import AddPendingList from '../components/Admin/Abstract/Article/AddPendingList';
import RejectList from '../components/Admin/Abstract/Article/RejectList';
import AddRejectList from '../components/Admin/Abstract/Article/AddRejectList';

//isssue
import IssuePendingArticle from '../components/Admin/Abstract/Article/IssuePendingArticle';
import AddIssuePending from '../components/Admin/Abstract/Article/AddIssuePending';
//Reviewer
import Reviewer from '../components/Admin/Abstract/Reviewer/Reviewer';
import ModeratorList from '../components/Admin/Abstract/Reviewer/ModeratorList';
import AddReviewer from '../components/Admin/Abstract/Reviewer/AddReviewer';
import EditReviewer from '../components/Admin/Abstract/Reviewer/EditReviewer';
import ReviewerReport from '../components/Admin/Abstract/Reviewer/ReviewerReport';
//Authors
import Author from '../components/Admin/Abstract/Author/Author';
//comments
import Comments from '../components/Admin/Abstract/Comments/Comments';
//Requirements
import Requirements from '../components/Admin/Abstract/Requirements/Requirements';
import AddRequirements from '../components/Admin/Abstract/Requirements/AddRequirements';
import EditRequirements from '../components/Admin/Abstract/Requirements/EditRequirements';
//Profile 
import Profile from '../components/Admin/Abstract/Profile/Profile';
import QustionsAnswer from '../components/Admin/AbstractScore/QustionsAnswer';
import AddQuestionScore from '../components/Admin/AbstractScore/AddQuestionScore';
import ViewQuestion from '../components/Admin/AbstractScore/ViewQuestion';
import EditQuestion from '../components/Admin/AbstractScore/EditQuestion';
import AllReview from '../components/Admin/AbstractScore/Score';
import Summary from '../components/Admin/AbstractScore/Summary';
import ShowSingleAbstractSummary from '../components/Admin/AbstractScore/ShowSingleAbstractSummary';
import UploadFinalPersentation from '../components/Admin/AbstractScore/UploadFinalPersentation';
//setting
import EmailSettings from '../components/Admin/settings/EmailSettings';
import CustomField from '../components/Admin/settings/CustomField';
import ListCustomFiels from '../components/Admin/settings/ListCustomFiels';
import InactiveListCustomFields from '../components/Admin/settings/InactiveListCustomFields';
import EditCustomField from '../components/Admin/settings/EditCustomField';
import GenralSetting from '../components/Admin/settings/GenralSetting';
import TestingEmail from '../components/Admin/settings/TestingEmail';
import SendMailToAllParticpant from '../components/Admin/settings/SendMailToAllParticpant';
// import Qrcode from '../components/Admin/Exhibition/QrCode/Qrcode';
//Notifiction
import Notifictions from '../components/Admin/Abstract/Notifiction/Notifictions';
//instructions
import Instruction from '../components/Admin/Abstract/Instructions/Instruction';
import AddInstruction from '../components/Admin/Abstract/Instructions/AddInstruction';
//eventIDaddFroAbstract
import AddEventID from '../components/Admin/Abstract/AddEventID';
import AuthorDashbords from '../components/Admin/Abstract/Author/AuthorDashbord';
//author edit
import EditArticle from '../components/Admin/Abstract/Author/EditArticle';
// Exhibition FrontEnd Routs 
import ExhibitionDashbord from '../components/Admin/Exhibition/ExhibitionDashbord';
 import BoothCategory from '../components/Admin/Exhibition/Category/BoothCategory';
 import AddBoothCategory from '../components/Admin/Exhibition/Category/AddBoothCategory';
 import Booth from '../components/Admin/Exhibition/Booth/Booth';
 import AddBooth from '../components/Admin/Exhibition/Booth/AddBooth';
 import EditBooth from '../components/Admin/Exhibition/Booth/EditBooth';
 import BookingBooth from '../components/Admin/Exhibition/Booking/BookingBooth';
 import HoldBooth from '../components/Admin/Exhibition/Booking/HoldBooth';
 import ReserveBooth from '../components/Admin/Exhibition/Booking/ReserveBooth';
 import ExhibitorCategory from '../components/Admin/Exhibition/Exhibitor/ExhibitorCategory/ExhibitorCategory';
 import EditExhibitorCategoy from '../components/Admin/Exhibition/Exhibitor/ExhibitorCategory/EditExhibitorCategoy';
import Exhibitor from '../components/Admin/Exhibition/Exhibitor/ExhibitorCategory/Exhibitor';
import AddNewExhibitor from '../components/Admin/Exhibition/Exhibitor/ExhibitorAdd/AddNewExhibitor';
import EditExhibitor from '../components/Admin/Exhibition/Exhibitor/ExhibitorAdd/EditExhibitor';
import AllExhibitor from '../components/Admin/Exhibition/Exhibitor/ExhibitorAdd/AllExhibitor';
import ExhibitorProfile from '../components/Admin/Exhibition/Exhibitor/ExhibitorAdd/ExhibitorProfile';
import GuestFromNatureOfBusiness from '../components/Admin/Exhibition/GuestFormFrontend/GuestFromNatureOfBusiness';
import GuestFromProductsAndServices from '../components/Admin/Exhibition/GuestFormFrontend/GuestFromProductsAndServices';
import IndexFromNature from '../components/Admin/Exhibition/GuestFormFrontend/IndexFromNature';
import IndexproductServices from '../components/Admin/Exhibition/GuestFormFrontend/IndexproductServices';
import EditProductServives from '../components/Admin/Exhibition/GuestFormFrontend/EditProductServives';
import EditNatureBusiness from '../components/Admin/Exhibition/GuestFormFrontend/EditNatureBusiness';
import ExhibitionBadge from '../components/Admin/Exhibition/ExhibitionBadge/ExhibitionBadge';
import EditBoothCategory from '../components/Admin/Exhibition/Category/EditBoothCategory';
import BadgeEditor from '../components/Admin/Exhibition/ExhibitionBadge/BadgeEditor';
import GusestVisiter from '../components/Admin/Exhibition/GuestVisit/GusestVisiter';
import IndexGuest from '../components/Admin/Exhibition/RegistratorGuest/IndexGuest';
import ScanByQrcode from '../components/Admin/Exhibition/RegistratorGuest/ScanByQrcode';
import EditGuest from '../components/Admin/Exhibition/RegistratorGuest/EditGuest';



const Routes = [
{path:'/admin', exact:true,name:'Admin'},
{path:'/admin/dashbord/:id'  ,name:'Dashbord',component:Dashbord},
{/*Event Routes*/},
{path:'/admin/events',exact:true,name:'Event',component:Events},
// {path:'/admin/add-event',exact:true,name:'Event',component:AddEVent},
// {/*user Routes*/},
 {path:'/admin/user',exact:true, name:'User', component:AllUsers},
 {path:'/admin/adduser',exact:true, name:'User', component:AddUser},
 {path:'/admin/speaker',exact:true, name:'User', component:AddSpeakser},
 
 {path:'/admin/edituser/:id',exact:true, name:'User', component:EidtUser},
 // {path:'/admin/showuser/:id',exact:true, name:'User', component:ShowUser},

//  Abstract Section Routes
 {path:'/admin/abstract/dashbord',exact:true, name:'Abstract' ,component:AbstractDashbord},
 {path:'/admin/abstract/add-category',exact:true, name:'Abstract' ,component:AddCategory},
 {path:'/admin/abstract/all-category',exact:true, name:'Abstract' ,component:AllCategory},
 //abstract SubmissionType
 {path:'/admin/abstract/add-submission',exact:true,name:'abstract' ,component:AddSubmissionType},
 {path:'/admin/abstract/submission',exact:true,name:'abstract' ,component:IndexSubmissionType},
 
 //Article Section Routes
 {path:'/admin/abstract/approve-article',exact:true, name:'Abstract' ,component:ApproveArticle},
 {path:'/admin/abstract/add-approve-article',exact:true, name:'Abstract' ,component:AddApproveArticle},
 {path:'/admin/abstract/edit-approve-article/:id',exact:true, name:'Abstract' ,component:EditApproveArticle},
 
 {path:'/admin/abstract/pending-article',exact:true, name:'Abstract' ,component:PendingList},
 {path:'/admin/abstract/add-pending-article',exact:true, name:'Abstract' ,component:AddPendingList},
 
 {path:'/admin/abstract/reject-article',exact:true, name:'Abstract' ,component:RejectList},
 {path:'/admin/abstract/add-reject-article',exact:true, name:'Abstract' ,component:AddRejectList},
 //issue

 {path:'/admin/abstract/issue-pending-article',exact:true, name:'Issue' ,component: IssuePendingArticle},
 {path:'/admin/abstract/add-issue-pending-article/:id',exact:true, name:'Issue' ,component:  AddIssuePending},

 //Comments
{path:'/admin/comments/comments',exact:true, name:'Comments' ,component:Comments},
//Requirements
{path:'/admin/requirements/requirements',exact:true, name:'Requirements' ,component:Requirements},
{path:'/admin/requirements/add-requirements',exact:true, name:'Requirements' ,component:AddRequirements},
{path:'/admin/requirements/eidt-requirements/:id' ,exact:true,name:'Requirements',component:EditRequirements},
 //Reviewer
 {path:'/admin/reviewer/reviewer-list',exact:true, name:'Reviewer' ,component:Reviewer},
 {path:'/admin/moderator/moderator-list',exact:true, name:'ModeratorList' ,component:ModeratorList},
 
 {path:'/admin/reviewer/add-reviewer',exact:true, name:'Reviewer' ,component:AddReviewer},
 {path:'/admin/reviewer/edit-reviewer/:id',exact:true, name:'Reviewer' ,component:EditReviewer},
 {path:'/admin/reviewer/report/:id',exact:true, name:'Reviewer' ,component:ReviewerReport},
 
 {path:'/admin/author/author-list',exact:true,name:'Author',component:Author},

//Profile setting
{path:'/admin/profile/profile',exact:true,name:'Profile',component:Profile},
{path:'/admin/questions/answers',exact:true,name:'QuestionAnswer',component:QustionsAnswer},
{path:'/admin/email/setting',exact:true,name:'EmailSettings',component:EmailSettings},
{path:'/admin/general/setting',exact:true,name:'GenralSetting',component:GenralSetting},
{path:'/admin/from/custom-field',exact:true,name:'CustomField',component:CustomField},
{path:'/admin/from/custom-list',exact:true,name:'ListCustomFiels',component:ListCustomFiels},
{path:'/admin/test/email',exact:true,name:'TestingEmail',component:TestingEmail},
{path:'/admin/email/partitcpant',exact:true,name:'TestingEmail',component:SendMailToAllParticpant},


{path:'/admin/from/inactive-custom-list',exact:true,name:'InactiveListCustomFields',component:InactiveListCustomFields},
{path:'/admin/from/edit/custom-list/:id',exact:true,name:'EditCustomField',component:EditCustomField},


// {path:'/admin/exhibition/qrcode',exact:true,name:'Qrcode',component:Qrcode},

//inside in penddinglist
{path:'/admin/abstract/add-score/:id',exact:true,name:'QuestionScore',component:AddQuestionScore},
{path:'/admin/abstract/view-score/:id',exact:true,name:'ViewScore',component:ViewQuestion},
{path:'/admin/abstract/edit-question/:id',exact:true,name:'EditScore',component:EditQuestion},
{path:'/admin/abstract/all-review/:id',exact:true,name:'AllReview',component:AllReview},
{path:'/admin/abstract/summary/:id',exact:true,name:'Summary',component:Summary},
{path:'/admin/abstract/singleabstract/:id',exact:true,name:'ShowSingleAbstractSummary',component:ShowSingleAbstractSummary},
{path:'/admin/final/presentation/:id',exact:true,name:'UploadFinalPersentation',component:UploadFinalPersentation},

//notifiction 
{path:'/admin/notifictions/notifictions',exact:true,name:'Notifictions',component:Notifictions},
//instructions
{path:'/admin/instruction/instruction',exact:true,name:'Instruction',component:Instruction},
{path:'/admin/addinstruction/addinstruction',exact:true,name:'Instruction',component:AddInstruction},
//AddEventID
{path:'/admin/addeventid',exact:true,name:'EventID',component:AddEventID},
//authorDashbord
{path:'/admin/author/dashbord',exact:true,name:'AuthorDashord',component:AuthorDashbords},
{path:'/admin/author/abstract/edit-approve-article/:id',exact:true,name:'EditArticle',component:EditArticle},

// Exhibition  Routs

{path:'/admin/exhibition/dashbord',exact:true,name:'ExhibitionDashbord',component:ExhibitionDashbord},
{path:'/admin/exhibition/boothcategory',exact:true,name:'BoothCategory',component:BoothCategory},
{path:'/admin/exhibition/addboothcategory',exact:true,name:'AddBoothCategory',component:AddBoothCategory},
{path:'/admin/exhibition/exhibitorcategory-edit/:id',exact:true,name:'EditBoothCategory',component:EditBoothCategory},

{path:'/admin/exhibition/booth',exact:true,name:'Booth',component:Booth},
{path:'/admin/exhibition/booking',exact:true,name:'BookingBooth',component:BookingBooth},
{path:'/admin/exhibition/reserve',exact:true,name:'ReserveBooth',component:ReserveBooth},
{path:'/admin/exhibition/hold',exact:true,name:'HoldBooth',component:HoldBooth},

{path:'/admin/exhibition/addbooth',exact:true,name:'AddBooth',component:AddBooth},
{path:'/admin/exhibition/edit-booth/:id',exact:true,name:'EditBooth',component:EditBooth},

{path:'/admin/exhibition/exhibitorcategory',exact:true,name:'ExhibitorCategory',component:ExhibitorCategory},
{path:'/admin/exhibition/exhibitorcate-edit/:id',exact:true,name:'EditExhibitorCategoy',component:EditExhibitorCategoy},

{path:'/admin/exhibition/exhibitor',exact:true,name:'Exhibitor',component:Exhibitor},
{path:'/admin/exhibition/addexhibitor',exact:true,name:'AddNewExhibitor',component:AddNewExhibitor},
{path:'/admin/exhibition/edit-exhibitor/:id',exact:true,name:'EditExhibitor',component:EditExhibitor},

{path:'/admin/exhibition/allexhibitor',exact:true,name:'AllExhibitor',component:AllExhibitor},
{path:'/admin/exhibition/exhibitor-profile',exact:true,name:'ExhibitorProfile',component:ExhibitorProfile},
{path:'/admin/exhibition/index-nature',exact:true,name:'IndexFromNature',component:IndexFromNature},
{path:'/admin/exhibition/natureofbusiness',exact:true,name:'GuestFromNatureOfBusiness',component:GuestFromNatureOfBusiness},
{path:'/admin/exhibition/productsandservices',exact:true,name:'GuestFromProductsAndServices',component:GuestFromProductsAndServices},
{path:'/admin/exhibition/index-services',exact:true,name:'IndexproductServices',component:IndexproductServices},
{path:'/admin/exhibition/product-services-edit/:id',exact:true,name:'EditProductServives',component:EditProductServives},
{path:'/admin/exhibition/nature-business-edit/:id',exact:true,name:'EditNatureBusiness',component:EditNatureBusiness},
{path:'/admin/exhibition/badge',exact:true,name:'ExhibitionBadge',component:ExhibitionBadge},
{path:'/admin/exhibition/visitor',exact:true,name:'GusestVisiter',component:GusestVisiter},
{path:'/admin/exhibition/guest',exact:true,name:'IndexGuest',component:IndexGuest},
{path:'/admin/exhibition/edit-guest/:id',exact:true,name:'EditGuest',component:EditGuest},
{path:'/admin/exhibitor/scan',exact:true,name:'ScanByQrcode',component:ScanByQrcode},



// {/*Role Routes*/},
 {path:'/admin/role',exact:true, name:'Role', component:AllRoles},
 {path:'/admin/create-role',exact:true, name:'AddRole', component:AddRole},

// {/*Permissions Routes*/},
 {path:'/admin/role/permissions/:id',exact:true,name:'Permissions',component:Permissions},

];



export default Routes;

