import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

const AddSpeakser = () => {
    const history = useHistory();
    const [allroles, setallroles] = useState([]);
    const [loading, setloading] = useState(false);
    const [adduser, setAdduser] = useState({

        name: "",
        email: "",
        password: "",
        error_list: [],
    });
    const [checkmodules, setCheckedmodules] = useState([]);

    const handlechnage = (e) => {

        const value = e.target.value;
        const checked = e.target.checked;

        if (checked) {
            setCheckedmodules([...checkmodules, value])
        }
        else {
            setCheckedmodules(checkmodules.filter((e) => (e !== value)));

        }

    }
    const [assignModule, setassignModules] = useState([]);
    useEffect(() => {

        //eventId 
        const eventid = localStorage.getItem('eventid')
        axios.get(`api/modules-access?id=${eventid}`).then(res => {

            if (res.status === 200) {
                console.log(res.data.mod);
                setassignModules(res.data.mod);
            }
            else {
                setloading(true);
            }


        });


    }, []);

    const [role, setRole] = React.useState('');
    const handleChange = (e) => {
        setRole(e.target.value);
    };


    const handleInput = (e) => {
        e.persist();
        setAdduser({ ...adduser, [e.target.name]: e.target.value });
    }
    const registerSubmit = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid')
        const data = {
            name: adduser.name,
            email: adduser.email,
            password: adduser.password,
            role,
            checkmodules,
            eventid,
        }
        // console.log(data);
        axios.get('/sanctum/csrf-cookie').then(response => {

            axios.post(`/api/orgniser-add-speaker`, data).then(res => {

                if (res.data.status === 200) {


                    swal('Success', "Speaker Added SuccessFully !!", 'success');
                    // Swal.fire({
                    //     position: 'top-end',
                    //     icon: 'success',
                    //     title: 'User Added SuccessFully !!',
                    //     showConfirmButton: false,
                    //     timer: 1500
                    //   })
                    history.push('/admin/user/');
                }
                else {

                    setAdduser({ ...adduser, error_list: res.data.validate_error })

                }

            });


        });

    }
    useEffect(() => {

        axios.get(`api/allroles`).then(res => {

            if (res.status === 200) {


                setallroles(res.data.roles);

            }
            else {
                setloading(true);
            }

        });


    }, []);

    if (loading) {
        return <h3>User Edit Loading....</h3>
    }
    return (

        <div style={{ margin: '1%', width: '60vw', marginLeft: '10%' }}>
            <Box sx={{ color: '#000000', }} style={{ marginTop: '5%' }}>
                <h4 sx={{ m: 2, color: '#000000' }} >
                    <Button sx={{
                        float: 'right',
                        marginTop: '2%',
                        ":hover": {
                            color: "white"
                        }
                    }}
                        color='success'
                        size='small'
                        variant='contained'
                        component={Link}
                        to="/admin/user">
                        BAck
                    </Button>
                </h4>
                <Card sx={{ width: '60vw', float: 'center' }} >
                    <CardContent >
                        <Typography component="h1" variant="h5">
                            Add Speakser
                        </Typography>
                        <Box component="form" onSubmit={registerSubmit} noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    <TextField
                                        autoComplete="given-name"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        autoFocus
                                        name='name' onChange={handleInput} value={adduser.name}
                                        helperText={adduser.error_list.name} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        autoComplete="email"
                                        name='email' onChange={handleInput} value={adduser.email}
                                        helperText={adduser.error_list.email} />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        name='password' onChange={handleInput} value={adduser.password}
                                        helperText={adduser.error_list.password} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel >Role</InputLabel>

                                        <Select defaultValue=""

                                            name='role' onChange={handleChange} value={role}
                                            label="Role">


                                            {
                                                allroles.map((item => {
                                                    return (
                                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                                    )

                                                }))
                                            }

                                        </Select>
                                        <FormHelperText>{adduser.error_list.role} </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormGroup row>
                                        {
                                            assignModule.map((item) => {
                                                return (

                                                    <FormControlLabel required control={<Checkbox />}
                                                        label={item.module}
                                                        onChange={handlechnage}
                                                        value={item.module_id}
                                                        name="checkmodules" key={item.id} />


                                                )
                                            })
                                        }
                                    </FormGroup>
                                    <FormHelperText>{adduser.error_list.checkmodules} </FormHelperText>
                                </Grid>

                            </Grid>

                            <Grid container justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }} >
                                    Add Speakser
                                </Button>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>

    )
}
export default AddSpeakser;
