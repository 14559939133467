import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom"
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import { Button, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import swal from "sweetalert";
import Swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import NewVersionNabvar from "../../../frontend/abstact/NewVersiion/NewVersionNabvar";
import NewVersionAbstractFooter from "./NewVersionAnstractFooter";
import { useMediaQuery, useTheme } from '@mui/material';

const NewVerstionAbstractLogin = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const bannerImage = `${process.env.PUBLIC_URL}/Abstract/loginBanerImage/abanner-bg.jpg`;
    //const bannerImage = `${process.env.PUBLIC_URL}/Abstract/loginBanerImage/scfms-bg.jpg`;

    const history = useHistory();
    const [loginInput, setLogin] = useState({
        email: "",
        password: "",
        error_list: [],

    });

    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });


    }
    const loginSubmit = (e) => {
        e.preventDefault();
    
        const data = {
            email: loginInput.email,
            password: loginInput.password,
        };
    
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/login', data).then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    localStorage.setItem('role', res.data.role);
    
                    // Add a console log to check response data
                    //console.log('Login response:', res.data);
    
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        didClose: () => {
                            // Redirection logic after the Swal modal closes
                            console.log('Role:', res.data.role);
                            if (res.data.role === 'superadmin') {
                                history.push('/admin');
                            } else if (res.data.role === 'organiser') {
                                history.push('/admin/events');
                            } else if (res.data.role === 'reviewer') {
                                history.push('/admin/events');
                            } else if (res.data.role === 'author') { // Ensure 'author' is checked in lowercase
                                history.push('/admin/events');
                            } else if (res.data.role === 'speaker') { // Ensure 'speaker' is checked in lowercase
                                history.push('/admin/events');
                            } else if (res.data.role === 'exhibitor') {
                                history.push('/admin/events');
                            } else {
                                console.log('Role did not match any known role:', res.data.role);
                            }
                        }
                    });
                } else if (res.data.status === 401) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    setLogin({ ...loginInput, error_list: res.data.validate_error });
                }
            });
        });
    };
    // const loginSubmit = (e) => {

    //     e.preventDefault();

    //     const data = {
    //         email: loginInput.email,
    //         password: loginInput.password,
    //     }
    //     axios.get('/sanctum/csrf-cookie').then(response => {

    //         axios.post(`api/login`, data).then(res => {
    //             if (res.data.status === 200) {
    //                 localStorage.setItem('auth_token', res.data.token);
    //                 localStorage.setItem('auth_name', res.data.username);
    //                 localStorage.setItem('role', res.data.role);
    //                 //swal('Success',res.data.message,'success');
    //                 Swal.fire({
    //                     position: 'top-end',
    //                     icon: 'success',
    //                     title: res.data.message,
    //                     showConfirmButton: false,
    //                     timer: 1500
    //                 })
    //                 if (res.data.role === 'superadmin') {
    //                     history.push('/admin');
    //                 }

    //                 else if (res.data.role === 'organiser') {
    //                     history.push('/admin/events');
    //                 }
    //                 else if (res.data.role === 'reviewer') {
    //                     history.push('/admin/events');
    //                 }

    //                 else if (res.data.role === 'Author') {
    //                     history.push('/admin/events');
    //                 }
    //                 else if (res.data.role === 'exhibitor') {
    //                     history.push('/admin/events');
    //                 }


    //             }
    //             else if (res.data.status === 401) {
    //                 //swal('Warning',res.data.message,'warning');
    //                 Swal.fire({
    //                     position: 'top-end',
    //                     icon: 'warning',
    //                     title: res.data.message,
    //                     showConfirmButton: false,
    //                     timer: 1500
    //                 })
    //             }
    //             else {
    //                 setLogin({ ...loginInput, error_list: res.data.validate_error });
    //             }


    //         });
    //     });
    // }

    //const gradientBackground = 'linear-gradient(90deg, rgba(20, 65, 102, 1) 0%, rgba(20, 66, 103, 1) 36%, rgba(7, 25, 39, 1) 100%)';
    const gradientBackground = '#05597D';
    return (
        <div >

            <Grid item xs={12} sm={10} md={8} lg={6}>
                <NewVersionNabvar />
            </Grid>


            <Grid container spacing={2}>
                <Box
                    sx={{
                        position: 'relative',
                        marginTop: '10px',
                        width: '100%',
                        height: isSmallScreen ? '200px' : '500px', // Adjust height for small screens
                        backgroundImage: `url(${bannerImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: isSmallScreen ? 'center' : 'left',
                        padding: isSmallScreen ? '10px' : '20px', // Adjust padding for small screens
                        color: 'white',
                    }}
                >
                    <Typography variant={isSmallScreen ? 'h4' : 'h2'} component="div">
                        Abstract
                    </Typography>
                </Box>

                <Grid container justifyContent="center" marginTop="40px">
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Box
                            sx={{
                                boxShadow: 3,
                                borderRadius: 2,
                                padding: 4,
                                width: '100%',
                                maxWidth: isSmallScreen ? '300px' : '600px', // Initial maxWidth for smaller screens
                                textAlign: 'center',
                                background: gradientBackground, // Replace with your gradient background
                                color: 'white', // Set the text color to white
                                marginX: 'auto', // Center the box horizontally
                                '@media (min-width: 960px)': {
                                    maxWidth: '800px', // Increase maxWidth on larger screens if needed
                                },
                            }}
                        >
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <Box component="form" onSubmit={loginSubmit} noValidate sx={{ mt: 1 }}>
                                {/* Email Address Input */}
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoComplete="off"
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoFocus
                                    onChange={handleInput}
                                    value={loginInput.email}
                                    helperText={loginInput.error_list.email}
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Set label text color
                                    }}
                                    FormHelperTextProps={{
                                        style: { color: 'white' }, // Set helper text color
                                    }}
                                    InputProps={{
                                        style: {
                                            color: 'white', // Set text color inside the input
                                            '&::placeholder': {
                                                color: 'white', // Set placeholder text color
                                                opacity: 0.7, // Adjust opacity if needed
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: 'white', // Set label text color
                                        },
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '25px', // Adjust the value as needed
                                            color: 'white', // Set text color inside the input field
                                            '& fieldset': {
                                                borderColor: 'white', // Set border color for the input
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white', // Set border color on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white', // Set border color when focused
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white !important', // Ensure label text color is white
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: 'white !important', // Ensure helper text color is white
                                        },
                                    }}
                                />



                                {/* Password Input */}
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    autoComplete="off"
                                    type="password"
                                    id="password"
                                    onChange={handleInput}
                                    value={loginInput.password} // Replace with your state variable for password input value
                                    helperText={loginInput.error_list.password} // Replace with your state variable for password input error message
                                    InputLabelProps={{
                                        sx: {
                                            color: 'white', // Set label text color
                                        },
                                    }}
                                    FormHelperTextProps={{
                                        sx: {
                                            color: 'white', // Set helper text color
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            color: 'white', // Set text color inside the input
                                            '&::placeholder': {
                                                color: 'white', // Set placeholder text color
                                                opacity: 0.7, // Adjust opacity if needed
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '25px', // Adjust the value as needed
                                            color: 'white', // Set text color inside the input field
                                            '& fieldset': {
                                                borderColor: 'white', // Set border color for the input
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'white', // Set border color on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white', // Set border color when focused
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white', // Set label text color
                                        },
                                        '& .MuiFormHelperText-root': {
                                            color: 'white !important', // Ensure helper text color is white
                                        },
                                    }}
                                />

                                {/* Remember me Checkbox */}
                                <FormControlLabel
                                    control={<Checkbox sx={{ color: 'white' }} />}
                                    label={<Typography sx={{ color: 'white' }}>Remember me</Typography>}
                                    sx={{ color: 'white' }} // Set label text color to white
                                />

                                {/* Sign In Button */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: '#1EB5B0',
                                        borderRadius: '25px', // Adjust the value as needed
                                    }}
                                >
                                    Sign In
                                </Button>

                                {/* Links Container */}
                                <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                                    <Grid item>
                                        <Link to="/forgot/password" style={{ color: 'white', textDecoration: 'none' }}>
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link to="/author/register" style={{ color: 'white', textDecoration: 'none' }}>
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={10} md={8} lg={6} marginTop="40px">
            <NewVersionAbstractFooter/>
            </Grid>
            
        </div>
    );
}


export default NewVerstionAbstractLogin;
