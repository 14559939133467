import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom"
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import { Button, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import swal from "sweetalert";
import Swal from 'sweetalert2'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


import Navbar from '../../frontend/abstact/Navbar';
const Login = () => {
    const history = useHistory();
    const [loginInput, setLogin] = useState({
        email: "",
        password: "",
        error_list: [],

    });

    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });


    }
    const loginSubmit = (e) => {
        e.preventDefault();
    
        const data = {
            email: loginInput.email,
            password: loginInput.password,
        };
    
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/login', data).then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    localStorage.setItem('role', res.data.role);
    
                    // Add a console log to check response data
                    console.log('Login response:', res.data);
    
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        didClose: () => {
                            // Redirection logic after the Swal modal closes
                            console.log('Role:', res.data.role);
                            if (res.data.role === 'superadmin') {
                                history.push('/admin');
                            } else if (res.data.role === 'organiser') {
                                history.push('/admin/events');
                            } else if (res.data.role === 'reviewer') {
                                history.push('/admin/events');
                            } else if (res.data.role === 'speaker') { 
                                history.push('/admin/events');
                            } else if (res.data.role === 'author') { // Ensure 'author' is checked in lowercase
                                history.push('/admin/events');
                            } else if (res.data.role === 'exhibitor') {
                                history.push('/admin/events');
                            } else {
                                console.log('Role did not match any known role:', res.data.role);
                            }
                        }
                    });
                } else if (res.data.status === 401) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    setLogin({ ...loginInput, error_list: res.data.validate_error });
                }
            });
        });
    };
    
    
    return (
        <div>
            <Navbar />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }} >   </Box>

                <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }} >

                    <Avatar sx={{ m: 1, backgroundColor: '#145348' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={loginSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            autoComplete="off"
                            id="email"
                            label="Email Address"
                            name="email"
                            autoFocus
                            onChange={handleInput} value={loginInput.email}

                            helperText={loginInput.error_list.email} />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            autoComplete="off"
                            type="password"
                            id="password"
                            onChange={handleInput} value={loginInput.password}
                            helperText={loginInput.error_list.password} />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: '#145348' }}

                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link to="/forgot/password" variant="body2" style={{ color: '#1976d2', }} >
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/abstract-register" variant="body2" style={{ color: '#1976d2' }}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

            </Container>
        </div>
    );
}

export default Login;
