import React, { useEffect, useState, useRef } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from '@mui/icons-material/Delete';
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}
const UploadFinalPresentation = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [getabstractTitle, setGetabstractTitle] = React.useState({});
    const history = useHistory();
    const abstractId = props.match.params.id;
    const eventid = localStorage.getItem('eventid');
    const [disable, setDisable] = useState(false);
    const [presentationFile, setPresentationFile] = useState(null);
    const fileInputRef = useRef(null);
    const [presentation, setPresentation] = useState({
        presentation_title: "",
        speaker_phone: "",
        error_list: {},
    });
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 1) : 0;
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        axios.get(`api/abstractTitle?id=${abstractId}`).then(res => {
            if (res.status === 200) {
                setGetabstractTitle(res.data.abstractTitle);
            } else {
                swal('Error', "Check API Something Wrong", 'error');
            }
        });
    }, [abstractId]);
    
    const presentationSubmit = (e) => {
        e.preventDefault();
        const eventid = localStorage.getItem('eventid');

        // Create a FormData object
        const formData = new FormData();
        formData.append('eventid', eventid);
        formData.append('abstractId', abstractId);
        formData.append('presentationFile', presentationFile); // Add the file

        console.log("Form Data: ", [...formData]); // Log the FormData

        setDisable(true);
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/speaker-submisson-persentation`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type
                },
            }).then(res => {
                if (res.data.status === 200) {
                    swal('Success', "Uploaded Successfully !!", 'success');
                    setPresentationFile(null);
                    // Trigger a re-fetch of the abstract title data
                    fileInputRef.current.value = '';
                    fetchAbstractTitle();
                } else {
                    setPresentation({ ...presentation, error_list: res.data.validate_error });
                    setDisable(false);
                }
            }).catch(error => {
                console.error("Error uploading presentation: ", error.response.data); // Log any error response
                setDisable(false); // Re-enable the button on error
            });
        });
    };

    // Function to fetch the abstract title data
    const fetchAbstractTitle = () => {
        axios.get(`api/abstractTitle?id=${abstractId}`).then(res => {
            if (res.status === 200) {
                setGetabstractTitle(res.data.abstractTitle);
            } else {
                swal('Error', "Check API Something Wrong", 'error');
            }
        });
    };

    // Initial call to fetch the abstract title when the component loads

    const [speakerPresentations, setSpeakerPresentations] = useState(getabstractTitle.speaker_presentations || []);
    const deletePeresentation = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Deleting...";

        axios.get(`api/speakerPersentationstatus/${id}`).then(res => {
            if (res.data.status === 200) {
                setSpeakerPresentations(prevPresentations =>
                    prevPresentations.filter(presentation => presentation.id !== id)

                );
            } else if (res.data.status === 404) {
                thisClicked.innerText = "Delete";
            }
        }).catch(() => {
            thisClicked.innerText = "Delete"; // Reset text on error
        });
    };
    useEffect(() => {
        fetchAbstractTitle();
    }, [abstractId]);

    useEffect(() => {
        fetchAbstractTitle();
    }, [speakerPresentations]);

   

    let backGroundButtonByEvent = "";
    if (eventid == 63) {
        backGroundButtonByEvent = '#1EB5B0'; // Assign the value conditionally
    }
    else {
        backGroundButtonByEvent = '#145348'; // Default background color
    }

    // Helper functions to check file types
    const isPDF = (fileName) => {
        return fileName.toLowerCase().endsWith('.pdf');
    };

    const isPPT = (fileName) => {
        return fileName.toLowerCase().endsWith('.ppt') || fileName.toLowerCase().endsWith('.pptx');
    };
    return (
        <div style={{ marginTop: '3%', width: '100vw' }}>
            <Box sx={{ width: '100%' }}>
                <Button
                    sx={{
                        float: 'right',
                        marginRight: '1%',
                        marginTop: '2%',
                        backgroundColor: backGroundButtonByEvent,
                        ":hover": { color: "white" },
                    }}
                    color="success"
                    size="small"
                    variant="contained"
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={3}>
                        <h1>Abstract Title: {getabstractTitle?.title}</h1>
                        <h2>Writer Name: {getabstractTitle?.writer?.name}</h2>
                        <h2 >Writer Email:{getabstractTitle?.writer?.email}</h2>
                        <h2 >Writer Phone: {getabstractTitle?.writer?.phone}</h2>
                        <hr />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ marginTop: '5%' }}>
                <Card sx={{ width: '82vw' }}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Final Presentation
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center', // Centers the form horizontally
                                width: '100%',             // Full width of the screen
                            }}
                        >
                            <Box
                                component="form"
                                sx={{
                                    mt: 3, // Optional: Add margin top if needed
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Centers items horizontally inside the form
                                    padding: '20px', // Optional: padding around the form content
                                    border: '1px solid #ccc', // Optional: adds a border around the form
                                    borderRadius: '8px', // Optional: rounded corners
                                    width: '80%', // Adjust width as needed (e.g., 80% of the container's width)
                                    maxWidth: '600px', // Optional: to limit the maximum width
                                }}
                                onSubmit={presentationSubmit}
                            >
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={6}>
                                        <label htmlFor="contained-button-file">
                                            <TextField
                                                id="contained-button-file"
                                                type="file"
                                                ref={fileInputRef}
                                                onChange={(e) => setPresentationFile(e.target.files[0])}
                                                inputProps={{ accept: '.ppt, .pptx, .pdf' }} // Accept both PPT and PDF files
                                            />
                                            <IconButton color="primary" aria-label="upload picture" component="span">
                                                <AttachmentIcon />
                                            </IconButton>
                                        </label>
                                        {presentation.error_list.presentationFile ? (
                                            <FormHelperText>{presentation.error_list.presentationFile}</FormHelperText>
                                        ) : (
                                            <Typography
                                                component="span"
                                                style={{ color: "#311b92", fontSize: "90%" }}
                                            >
                                                <br />
                                                Note: The file must be of type:  PPT, or PPTX , max-size 30MB
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    variant="contained"

                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        padding: '6px 16px',  // Smaller padding for a compact look
                                        fontSize: '0.875rem',  // Smaller font size
                                        minWidth: '120px',     // Set a minimum width as needed
                                        alignSelf: 'center',
                                        backgroundColor: backGroundButtonByEvent,    // Centers the button within the form
                                    }}
                                    disabled={disable}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                        {/* Table Section */}
                        <Box sx={{ mt: 3 }}>

                            <TableContainer component={Paper}>

                                <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">

                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell align="left">Presentation</TableCell>
                                            <TableCell align="left">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getabstractTitle?.speaker_presentations && getabstractTitle.speaker_presentations.length > 0 ? (
                                            getabstractTitle.speaker_presentations
                                                .filter(presentation => presentation.status === 1)  // Filter presentations where status is 1
                                                .map((presentation, index) => (
                                                    <TableRow key={presentation.id}>
                                                        <TableCell component="th" scope="row">{index + 1}</TableCell> {/* Using index+1 for row number */}
                                                        {/* <TableCell align="left">
                                                            <a href={`${getabstractTitle.base_url}/speaker-presentation/${presentation.speaker_presentation}`}
                                                                download={`${getabstractTitle.base_url}/speaker-presentation/${presentation.speaker_presentation}`}
                                                                target="_blank" rel="noreferrer">
                                                                <Button
                                                                    sx={{
                                                                        margin: 1,
                                                                        backgroundColor:backGroundButtonByEvent, 
                                                                        ":hover": { backgroundColor: "lightgray" },
                                                                    }}
                                                                    color="primary"
                                                                    size="small"
                                                                    variant="contained"
                                                                >
                                                                    Download
                                                                </Button>
                                                            </a>
                                                        </TableCell> */}
                                                        <TableCell align="left">
                                                            {presentation.speaker_presentation && (
                                                                <a
                                                                    href={`${getabstractTitle.base_url}/speaker-presentation/${presentation.speaker_presentation}`}
                                                                    download={isPPT(presentation.speaker_presentation) ? `${presentation.speaker_presentation}` : undefined}
                                                                    target={isPDF(presentation.speaker_presentation) ? "_blank" : undefined}
                                                                    rel="noreferrer"
                                                                >
                                                                    <Button
                                                                        sx={{
                                                                            margin: 1,
                                                                            backgroundColor: backGroundButtonByEvent,
                                                                            ":hover": { backgroundColor: "lightgray" },
                                                                        }}
                                                                        color="primary"
                                                                        size="small"
                                                                        variant="contained"
                                                                    >
                                                                        {isPDF(presentation.speaker_presentation) ? 'View PDF' : 'Download PPT'}
                                                                    </Button>
                                                                </a>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Button
                                                                sx={{
                                                                    margin: 1,
                                                                    ":hover": { color: "white" },
                                                                    backgroundColor: backGroundButtonByEvent,
                                                                }}
                                                                color="primary"
                                                                size="small"
                                                                variant="contained"
                                                                onClick={(e) => deletePeresentation(e, presentation.id)}
                                                            >
                                                                <DeleteIcon />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={3}>No presentations available</TableCell>
                                            </TableRow>
                                        )}

                                        {/* Render empty rows if necessary */}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={3} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={3}
                                                count={1}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

        </div>
    );
}

export default UploadFinalPresentation;
