import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';  // Make sure this line is present
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";
import swal from "sweetalert";
import { CSVLink } from 'react-csv';
import { useHistory } from "react-router-dom";
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const ReviewerReport = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [reviewer, setSingleReviewer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const history = useHistory();
    const id = props.match.params.id;
    const eventid = localStorage.getItem('eventid');

    useEffect(() => {
        const fetchReviewer = async () => {
            try {
                const res = await axios.get(`api/reviewer-report/${id},${eventid}`);
                if (res.status === 200) {
                    setSingleReviewer(res.data.reviewer);
                } else {
                    swal('Error', "Check API Something Wrong", 'error');
                }
            } catch (error) {
                setError(error);
                swal('Error', 'An error occurred while fetching data', 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchReviewer();
    }, [id, eventid]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (reviewer?.user_abstracts?.length || 0)) : 0;



    // Prepare CSV data
    // const csvData = reviewer?.user_abstracts.map(abstract => ({
    //     Title: abstract.title || "",
    //     Writer: abstract.writer?.name || "",
    //     "Issue Title": abstract.issues.map(issue => issue.title).join(", ") || "",
    //     "Issue Description": abstract.issues.map(issue => issue.description).join(", ") || "",
    //     Questions: abstract.rating.map(rating => rating.question.question).join(", ") || "",
    //     "Obtain Scores": abstract.rating.map(rating => rating.obtain_scores).join(", ") || "",
    //     "Total Scores": abstract.rating.map(rating => rating.total_scores).join(", ") || "",
    // })) || [];


    // //start
    // const headers = [
    //     { label: 'Abstract Title', key: 'Abstract Title' },
    //     { label: 'Author', key: 'Author' },
    //     { label: 'Posted Issue Title', key: 'Posted Issue Title' },

    //     { label: 'Posted Issue Description', key: 'Posted Issue Description' },
    //     { label: 'Questions', key: 'Questions' },
    // ];
    // const handleDownloadCSV = () => {
    //     // Define the headers for the CSV file
    //     const headers = [
    //         'Abstract Title',
    //         'Author',
    //         'Posted Issue Title',
    //         'Posted Issue Description',
    //         'Questions',
    //         'Obtain Scores',
    //         'Total Scores',
    //     ];

    //     const escapeCsvValue = (value) => {
    //         if (value === null || value === undefined) return '';
    //         // Replace double quotes with two double quotes
    //         const escapedValue = value.toString().replace(/"/g, '""');
    //         // Add line breaks
    //         return `"${escapedValue}"`;
    //     };

    //     // Function to split text into multiple lines if it exceeds a specified length
    //     const splitIntoLines = (text, maxWords) => {
    //         const words = text.split(" ");
    //         let lines = [];
    //         for (let i = 0; i < words.length; i += maxWords) {
    //             lines.push(words.slice(i, i + maxWords).join(" "));
    //         }
    //         return lines.join("\n");
    //     };

    //     // Convert the data to CSV format
    //     const csvContent = [
    //         headers.join(','), // Add headers row
    //         ...reviewer.user_abstracts.map((abstract) => [
    //             escapeCsvValue(abstract.title),
    //             escapeCsvValue(abstract.writer?.name),
    //             escapeCsvValue(splitIntoLines(abstract.issues.map(issue => issue.title).join(", "), 50)), // Issues titles in one cell with line breaks
    //             escapeCsvValue(splitIntoLines(abstract.issues.map(issue => issue.description).join(", "), 50)), // Issues descriptions in one cell with line breaks
    //             escapeCsvValue(splitIntoLines(abstract.rating.map(rating => rating.question.question).join(", "), 50)), // Questions in one cell with line breaks
    //             escapeCsvValue(splitIntoLines(abstract.rating.map(rating => rating.obtain_scores).join(", "), 50)), // Obtain Scores in one cell with line breaks
    //             escapeCsvValue(splitIntoLines(abstract.rating.map(rating => rating.total_scores).join(", "), 50)), // Total Scores in one cell with line breaks
    //         ].join(',')),
    //     ].join('\n');

    //     // Create a Blob and download the CSV file
    //     const blob = new Blob([csvContent], { type: 'text/csv' });
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `${reviewer.name}_Report`); // Change the file name if needed
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };




    // const handleDownloadCSV = () => {
    //     // Define the headers for the CSV file
    //     const headers = [
    //         'Abstract Title',
    //         'Author',
    //         'Posted Issue Title',
    //         'Posted Issue Description',
    //         'Questions',
    //         'Obtain Scores',
    //         'Total Scores',
    //     ];

    //     const escapeCsvValue = (value) => {
    //         if (value === null || value === undefined) return '';
    //         // Replace double quotes with two double quotes
    //         const escapedValue = value.toString().replace(/"/g, '""');
    //         // Add line breaks
    //         return `"${escapedValue}"`;
    //     };

    //     // Function to split text into multiple lines if it exceeds a specified length
    //     const splitIntoLines = (text, maxWords) => {
    //         if (!text) return '';
    //         const words = text.split(" ");
    //         let lines = [];
    //         for (let i = 0; i < words.length; i += maxWords) {
    //             lines.push(words.slice(i, i + maxWords).join(" "));
    //         }
    //         return lines.join("\n");
    //     };

    //     // Select the data source based on the rendered context
    //     const abstracts = reviewer.abstracts || reviewer.user_abstracts || []; // Ensure `abstracts` is properly defined

    //     // Debugging: Check if abstracts has data
    //     console.log('Data to be exported:', abstracts);

    //     if (!Array.isArray(abstracts) || abstracts.length === 0) {
    //         console.error('No data available for CSV download');
    //         return; // Exit if no data
    //     }

    //     // Convert the data to CSV format
    //     const csvContent = [
    //         headers.join(','), // Add headers row
    //         ...(abstracts.map((abstract) => {
    //             // Debugging: Check each abstract's data
    //             console.log('Processing abstract:', abstract);

    //             const issuesTitles = abstract.issues ? abstract.issues.map(issue => issue.title).join(", ") : '';
    //             const issuesDescriptions = abstract.issues ? abstract.issues.map(issue => issue.description).join(", ") : '';
    //             const questions = abstract.rating 
    //                 ? abstract.rating.map(rating => rating.question ? rating.question.question : 'No question available').join(", ")
    //                 : 'No question available';
    //             const obtainScores = abstract.rating 
    //                 ? abstract.rating.map(rating => rating.obtain_scores || 'No score available').join(", ")
    //                 : 'No score available';
    //             const totalScores = abstract.rating 
    //                 ? abstract.rating.map(rating => rating.total_scores || 'No score available').join(", ")
    //                 : 'No score available';

    //             return [
    //                 escapeCsvValue(abstract.title),
    //                 escapeCsvValue(abstract.writer?.name),
    //                 escapeCsvValue(splitIntoLines(issuesTitles, 50)), // Issues titles in one cell with line breaks
    //                 escapeCsvValue(splitIntoLines(issuesDescriptions, 50)), // Issues descriptions in one cell with line breaks
    //                 escapeCsvValue(splitIntoLines(questions, 50)), // Questions in one cell with line breaks
    //                 escapeCsvValue(splitIntoLines(obtainScores, 50)), // Obtain Scores in one cell with line breaks
    //                 escapeCsvValue(splitIntoLines(totalScores, 50)), // Total Scores in one cell with line breaks
    //             ].join(',');
    //         })),
    //     ].join('\n');

    //     // Debugging: Check the CSV content
    //     console.log('Generated CSV content:', csvContent);

    //     // Create a Blob and download the CSV file
    //     const blob = new Blob([csvContent], { type: 'text/csv' });
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `${reviewer.name}_Report.csv`); // Change the file name if needed
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };


    console.log('Reviewer User Abstracts:', reviewer.user_abstracts);

    const handleDownloadCSV = (dataType) => {

        console.log('Selected dataType:', dataType);
        const dataCategory = reviewer.abstracts;
        const dataAbstract = reviewer.user_abstracts
        // const data = dataAbstract || dataCategory;

        let data;
        if (Array.isArray(dataAbstract) && Array.isArray(dataCategory)) {
            data = [...(dataAbstract || []), ...(dataCategory || [])];
        } else if (typeof dataAbstract === 'object' && typeof dataCategory === 'object') {
            data = { ...(dataAbstract || {}), ...(dataCategory || {}) };
        } else {
            data = dataAbstract || dataCategory;
        }

        console.log('Data to be downloaded:', data);

        if (!Array.isArray(data) || data.length === 0) {
            console.error('No data available for CSV download');
            return;
        }

        const headers = [
            'Abstract Title',
            'Author',
            'Posted Issue Title',
            'Posted Issue Description',
            'Questions',
            'Obtain Scores',
            'Total Scores',
        ];

        const escapeCsvValue = (value) => {
            if (value === null || value === undefined) return '';
            const escapedValue = value.toString().replace(/"/g, '""');
            return `"${escapedValue}"`;
        };

        const splitIntoLines = (text, maxWords) => {
            if (!text) return '';
            const words = text.split(" ");
            let lines = [];
            for (let i = 0; i < words.length; i += maxWords) {
                lines.push(words.slice(i, i + maxWords).join(" "));
            }
            return lines.join("\n");
        };



        // Convert the data to CSV format
        const csvContent = [
            headers.join(','),
            ...(data.map((item) => {
                const issuesTitles = item.issues ? item.issues.map(issue => issue.title || 'No title').join(", ") : 'No issues posted';
                const issuesDescriptions = item.issues ? item.issues.map(issue => issue.description || 'No description').join(", ") : 'No issues posted';
                const questions = item.rating
                    ? item.rating.map(rating => rating.question ? rating.question.question || 'No question available' : 'No question available').join(", ")
                    : 'No question available';
                const obtainScores = item.rating
                    ? item.rating.map(rating => rating.obtain_scores || 'No score available').join(", ")
                    : 'No score available';
                const totalScores = item.rating
                    ? item.rating.map(rating => rating.total_scores || 'No score available').join(", ")
                    : 'No score available';

                return [
                    escapeCsvValue(item.title || 'No title'),
                    escapeCsvValue(item.writer?.name || 'No author'),
                    escapeCsvValue(splitIntoLines(issuesTitles, 50)),
                    escapeCsvValue(splitIntoLines(issuesDescriptions, 50)),
                    escapeCsvValue(splitIntoLines(questions, 50)),
                    escapeCsvValue(splitIntoLines(obtainScores, 50)),
                    escapeCsvValue(splitIntoLines(totalScores, 50)),
                ].join(',');
            })),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reviewer.name}_Report.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    let backGroundButtonByEvent = ''; // Declare the variable outside

    if (eventid == 63) {
        backGroundButtonByEvent = '#1EB5B0'; // Assign the value conditionally
    }
    else {
        backGroundButtonByEvent = '#145348'; // Default background color
    }
    return (
        <div style={{ margin: '1%', width: '100vw', marginLeft: '0%' }}>
            <Box sx={{ color: '#000000' }} style={{ marginTop: '5%' }}>
                <Card sx={{ width: '82vw', margin: 'auto' }}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Reviewer Report
                        </Typography>
                        <Button
                            sx={{
                                float: 'right',
                                marginLeft: '2%',
                                backgroundColor: backGroundButtonByEvent,
                                ":hover": { color: "white" }
                            }}
                            color='success'
                            size='small'
                            variant='contained'
                            onClick={() => history.goBack()}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={handleDownloadCSV}
                            sx={{
                                float: 'right',
                                backgroundColor: backGroundButtonByEvent,
                                ":hover": { color: "white" }
                            }}
                            color='success'
                            size='small'
                            variant='contained'
                        >
                            Download CSV
                        </Button>

                        {reviewer ? (
                            <Box component="div" noValidate sx={{ mt: 3 }}>
                                <Typography variant="h6">{reviewer.name}</Typography>
                                <Typography>Email: {reviewer.email}</Typography>
                                <Typography>Phone: {reviewer.phone}</Typography>
                                <Typography>Event Name: {reviewer.eventname}</Typography>
                                <Typography>Total Assigned Abstracts: {reviewer.user_abstracts.length || reviewer.abstracts.length}</Typography>

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: 50 }}>#</TableCell>
                                                <TableCell align="left" style={{ minWidth: 150 }}>Abstract Title</TableCell>
                                                <TableCell align="left" style={{ minWidth: 120 }}>Author</TableCell>
                                                <TableCell align="left" style={{ minWidth: 200 }}>Posted Issue Title</TableCell>
                                                <TableCell align="left" style={{ minWidth: 200 }}>Posted Issue Description</TableCell>
                                                <TableCell align="left" style={{ minWidth: 200 }}>Questions</TableCell>
                                                <TableCell align="left" style={{ minWidth: 150 }}>Score</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(reviewer.abstracts && reviewer.abstracts.length > 0 ? reviewer.abstracts : reviewer.user_abstracts || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((abstract, i) => (
                                                <TableRow key={abstract.id}>
                                                    <TableCell component="th" scope="row" style={{ width: 50 }}>{i + 1}</TableCell>
                                                    <TableCell align="left" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{abstract.title || "N/A"}</TableCell>
                                                    <TableCell align="left">{abstract.writer?.name || "N/A"}</TableCell>
                                                    <TableCell align="left" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                        {abstract.issues?.length > 0 ? (
                                                            <ul>
                                                                {abstract.issues.map(issue => (
                                                                    <li key={issue.id}>{issue.title}</li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p>No issues posted</p>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                        {abstract.issues?.length > 0 ? (
                                                            <ul>
                                                                {abstract.issues.map(issue => (
                                                                    <li key={issue.id}>{issue.description}</li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p>No issues posted</p>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {abstract.rating?.length > 0 ? (
                                                            <ul>
                                                                {abstract.rating.map(rating => (
                                                                    <li key={rating.id}>{rating.question?.question || "No question available"}</li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p>No ratings available</p>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {abstract.rating?.length > 0 ? (
                                                            <ul>
                                                                {abstract.rating.map(rating => (
                                                                    <li key={rating.id}>
                                                                        <p>Obtain Score: {rating.obtain_scores || "N/A"}</p>
                                                                        <p>Total Score: {rating.total_scores || "N/A"}</p>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p>No ratings available</p>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={7} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                    colSpan={7}
                                                    count={(reviewer.abstracts?.length || reviewer.user_abstracts?.length) || 0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : (
                            <div>No reviewer found</div>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

export default ReviewerReport;
