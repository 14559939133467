import React, { useState } from 'react'
import axios from "axios";
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Menu } from '@mui/material';
import { MenuItem } from '@mui/material';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import RateReviewIcon from '@mui/icons-material/RateReview';
//import Badge from '@mui/material/Badge';

import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ArticleIcon from '@mui/icons-material/Article';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import FeedIcon from '@mui/icons-material/Feed';
import CommentIcon from '@mui/icons-material/Comment';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InfoIcon from '@mui/icons-material/Info';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AddCardIcon from '@mui/icons-material/AddCard';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BadgeIcon from '@mui/icons-material/Badge';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { useLocation } from 'react-router-dom';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

import MailIcon from "@mui/icons-material/Mail";

import { Button } from '@mui/material';


import swal from "sweetalert";
import CancelIcon from '@mui/icons-material/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import CheckIcon from '@mui/icons-material/Check';
import Badge from '@mui/material/Badge';
import moment from 'moment';
const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));



function NavBar() {
  const location = useLocation();
  const currentPath = location.pathname;
  const [module, setModules] = useState([]);
  const [loadning, setLoadning] = useState(false);
  const [opensnoti, setOpensNoti] = useState(false);
  const [permissions, setrPermissions] = useState([]);
  const [anchorElmNoti, setAnchorElmNoti] = useState(null);
  const [notificationRead, setNotificationRead] = React.useState([]);
  const [abstractNotification, setAbstractNotification] = React.useState([]);
  const [abstractNotificationcount, setAbstractNotificationCount] = React.useState([]);
  const eventid = localStorage.getItem('eventid')
  const AuthUserName = localStorage.getItem('auth_name');
  const organiserId = localStorage.getItem('user_id');
  const selfprint = localStorage.getItem('auth_name');
  const eventName = localStorage.getItem('eventname');
  const [reloadCounter, setReloadCounter] = useState(0);

  React.useEffect(() => {
    axios.get(`api/getpermissionByRole`).then(res => {

      if (res.status === 200) {
        const datad = res.data.data.permissions;
        setrPermissions(datad)
      } else {

      }
    })

  }, []);


  const fetchData = async () => {
    try {

      const response = await axios.get(`api/modules-access/${eventid},${organiserId}`);
      setModules(response.data.mod);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorElm, setAnchorElm] = useState(null);
  const [opens, setOpens] = useState(false);
  const handleClose = () => {
    setAnchorElm(null);
    setOpens(false);

  }
  const handleClick = (e) => {

    setAnchorElm(e.currentTarget);
    setOpens(true);

  }


  React.useEffect(() => {
    axios.get(`api/notificationTotalcount`).then(res => {

      if (res.status === 200) {
        const setAbstractReviewerData = res.data;

        setAbstractNotificationCount(setAbstractReviewerData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);



  React.useEffect(() => {
    axios.get(`api/readnotificationCount`).then(res => {

      if (res.status === 200) {
        const setAbstractReviewerData = res.data;

        setNotificationRead(setAbstractReviewerData)

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })

  }, []);


  const readNotification = (e) => {
    e.preventDefault();


    axios.get(`api/markAsRead`).then(res => {

      if (res.status === 200) {
        const setNotification = res.data;

        swal('Success', res.data.message, 'success');
        window.location.reload(false);

      } else {
        swal('Error', "Check API Something Wrong", 'error');

      }
    })


  }

  const handleClickNoti = (e) => {

    setAnchorElmNoti(e.currentTarget);
    setOpensNoti(true);
    loadList();

  }

  const loadList = async () => {
    const restult = await axios.get(`api/notification`);

    setAbstractNotification(restult.data.notifications);

  }

  const handleCloseNoti = () => {
    setAnchorElmNoti(null);
    setOpensNoti(false);

  }

  const history = useHistory();

  const logoutSubmit = (e) => {
  
    e.preventDefault();

    axios.post(`api/logout`).then(res => {

      if (res.data.status === 200) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_name');
        //localStorage.removeItem('eventid');
        localStorage.removeItem('role');
        localStorage.removeItem('user_id');
        localStorage.removeItem('eventname');
        localStorage.removeItem('abstractId');


        //swal('Success',res.data.message,'success');
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        // Correctly handle the redirection
        if (eventid == 63) {
          history.push('/author/login');
        } else {
          history.push('/');
        }
      }

    });

  }


  const [openAbstractMenu, setOpenAbstractMenu] = React.useState(false);

  const handleClickAbstractMenu = () => {
    setOpenAbstractMenu(!openAbstractMenu);
  }

  const [openAbstractSubMenu, setOpenAbstractSubMenu] = React.useState(false);

  const handleClickAbstractSubMenu = () => {
    setOpenAbstractSubMenu(!openAbstractSubMenu);
  }

  const [openAbstractArticle, setOpenAbstractArticle] = React.useState(false);
  const handleClickAbstractArticle = () => {
    setOpenAbstractArticle(!openAbstractArticle);
  }
  const role = localStorage.getItem('role');
  const [openEdit, setOpenForEdit] = useState(false);

  //EMS
  const [openExhibitionMenu, setOpenExhibitionMenu] = React.useState(false);
  const handleClickExhibitionMenu = () => {
    setOpenExhibitionMenu(!openExhibitionMenu);
  }
  const [openExhibitionBooth, setOpenExhibitionBooth] = React.useState(false);
  const handleClickExhibitionBooth = () => {
    setOpenExhibitionBooth(!openExhibitionBooth);
  }
  const [openExhibitionExhibitor, setOpenExhibitionExhibitor] = React.useState(false);
  const handleClickExhibitionExhibitor = () => {
    setOpenExhibitionExhibitor(!openExhibitionExhibitor);
  }
  const [openExhibitionItemService, setOpenExhibitionItemService] = React.useState(false);
  const handleClickExhibitionItemService = () => {
    setOpenExhibitionItemService(!openExhibitionItemService);
  }
  const [openExhibitionSetting, setOpenExhibitionSetting] = React.useState(false);
  const handleClickExhibitionSetting = () => {
    setOpenExhibitionSetting(!openExhibitionSetting);
  }
  const [openExhibitionGuestFrom, setOpenExhibitionGuestFrom] = React.useState(false);
  const handleClickExhibitionGuestFrom = () => {
    setOpenExhibitionGuestFrom(!openExhibitionGuestFrom);
  }


  React.useEffect(() => {
    const organiserId = localStorage.getItem('user_id')
    axios.get(`api/modules-access/${eventid},${organiserId}`).then(res => {
      if (res.status === 200) {
        setModules(res.data.mod);
      }

    });
    if (reloadCounter < 5) {
      const timeoutId = setTimeout(() => {
        setReloadCounter(prevCounter => prevCounter + 1);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [reloadCounter]);


  const getBackgroundColor = () => {
    // Define your logic to determine the background color based on the ID

    if (eventid == 63) {
      return '#05597D'; // Green background for other IDs'
    } 
  };

  const paperStyle = {
    backgroundColor: getBackgroundColor(),
    // Adjust as needed
  };
  return (
    <div>
      <Box>
        <CssBaseline />

        <AppBar position="absolute" open={open} >
          <Toolbar
            style={paperStyle}
          // sx={{
          //   pr: '24px', // keep right padding when drawer closed
          //   backgroundColor: '#145348' ,
          // }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            {currentPath === '/admin/events' ?

              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }} >
                Dashboard
              </Typography>
              :
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }} >
                {eventName}
              </Typography>
            }

            <IconButton
              sx={{
                color: "white",
                cursor: "pointer",
                marginRight: "2%"
              }}>
              {AuthUserName}
            </IconButton>

            {
              abstractNotificationcount == "" ?
                <Badge badgeContent={"0"}>
                  <NotificationsActiveIcon sx={{
                    color: "white",
                    cursor: "pointer",
                  }} />
                </Badge> :
                <Badge badgeContent={abstractNotificationcount} >
                  <NotificationsActiveIcon sx={{
                    color: "white",
                    cursor: "pointer",

                  }}
                    onClick={handleClickNoti}
                  />
                </Badge>
            }


            <Menu anchorEl={anchorElmNoti} open={opensnoti} onClose={handleCloseNoti}>

              {

                abstractNotification.map((item) => {
                  return (
                    <MenuItem key={item.id}>
                      <Typography align="center">{item.data} {item.title} &nbsp;&nbsp;&nbsp;&nbsp; {moment.utc(item.created_at).local().startOf('seconds').fromNow()}</Typography>

                    </MenuItem>
                  )

                })

              }
              <Typography sx={{
                mt: 5,
                cursor: "pointer",
              }} align="center" onClick={readNotification} >Mark As Read
                <CheckIcon sx={{ color: '#1976d2', }} onClick={readNotification} />

              </Typography>
            </Menu>


            <IconButton onClick={handleClick}
              sx={{
                color: "white",
                cursor: "pointer",
                marginRight: "1%",
                marginLeft: "3%"

              }}>
              <SettingsIcon />
            </IconButton>
            <Menu anchorEl={anchorElm} open={opens} onClose={handleClose}>
              <MenuItem onClick={() => history.push('/admin/profile/profile')} >Profile  &nbsp;&nbsp;
                <ManageAccountsIcon
                  sx={{
                    color: '#1976d2',
                    ":hover": {
                      color: "#1976d2"
                    }
                  }}
                  onClick={() => history.push('/admin/profile/profile')} />
              </MenuItem>
              <MenuItem onClick={handleClose}>Setting  &nbsp;&nbsp;
                <AdminPanelSettingsIcon
                  sx={{
                    color: '#1976d2',
                  }}
                />
              </MenuItem>
              <MenuItem onClick={logoutSubmit}>LogOut  &nbsp;&nbsp;
                <PowerSettingsNewIcon
                  sx={{
                    color: '#1976d2',
                  }}
                />
              </MenuItem>

            </Menu>



          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],


            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">

            {/* {role === 'Author' ? '' : */}
            <ListItemButton onClick={() => history.push('/admin/events')}>
              <ListItemIcon
                sx={{
                  color: '#1976d2',
                }}
              >
                <EventNoteIcon onClick={() => history.push('/admin/events')} />
              </ListItemIcon>
              <ListItemText primary="All Events"
                onClick={() => history.push('/admin/events')} />
            </ListItemButton>
            {/* } */}

            {currentPath === '/admin/events' ? '' :


              <ListItemButton >
                <ListItemIcon sx={{
                  color: '#1976d2',
                }}
                >
                  {
                    permissions['p_user-list'] === true ?
                      <GroupAddIcon onClick={() => history.push('/admin/user')}
                      />
                      : ""}
                </ListItemIcon>
                {permissions['p_user-list'] === true ?
                  <ListItemText primary="User" onClick={() => history.push('/admin/user')} />
                  : ""}
              </ListItemButton>

            }
            
        

            {currentPath === '/admin/events' ? '' :
              <ListItemButton >
                <ListItemIcon sx={{
                  color: '#1976d2',
                }}>
                  {
                    permissions['p_role-list'] === true ?
                      <PlaylistAddCheckIcon onClick={() => history.push('/admin/role')} />
                      : ""}
                </ListItemIcon>
                {
                  permissions['p_role-list'] === true ?
                    <ListItemText primary="Role" onClick={() => history.push('/admin/role')} />
                    : ""}
              </ListItemButton>}

            {currentPath === '/admin/events' ? '' :


              <ListItemButton >
                {
                  permissions['p_custom-field-list'] === true ?
                    <>
                      <ListItemIcon sx={{
                        color: '#1976d2',
                      }}>


                        <PlaylistAddCheckIcon onClick={() => history.push('/admin/from/custom-list')} />

                      </ListItemIcon>

                      <ListItemText primary="Custom Field" onClick={() => history.push('/admin/from/custom-list')} />
                    </> : ""}
              </ListItemButton>
            }

            {currentPath === '/admin/events' ? '' :
              permissions['p_event-smtp-setting'] === true ?

                < ListItemButton >
                  <ListItemIcon sx={{
                    color: '#1976d2'
                  }}>
                    <BadgeIcon onClick={() => history.push('/admin/email/setting')} />
                  </ListItemIcon >
                  <ListItemText primary="Email Setting" onClick={() => history.push('/admin/email/setting')} />
                </ListItemButton> : ""}




            {/* Start Abstract MS   */}

            <Divider sx={{ my: 1 }} />
            {
              module.map((item, index) => {

                if (item.module === 'AMS') {
                  return (
                    <div key={item.id}>
                      <Box key={item.id}>
                        {currentPath === '/admin/events' ? '' :
                          <ListItemButton onClick={handleClickAbstractMenu} sx={{
                            color: '#1976d2'
                          }} >
                            <ListItemIcon sx={{
                              color: '#1976d2'
                            }} >
                              <DashboardIcon onClick={() => history.push('/admin/abstract/dashbord')} />
                            </ListItemIcon >
                            <ListItemText primary="Abstract MS" sx={{
                              color: '#1976d2'
                            }} />
                            {openAbstractMenu ? <ExpandLess /> : <ExpandMore sx={{
                              color: '#1976d2'
                            }} />}
                          </ListItemButton>}

                        <Collapse in={openAbstractMenu} timeout="auto" unmountOnExit >
                          {currentPath === '/admin/events' ? '' :
                            <List component="div" disablePadding>

                              <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/abstract/dashbord')}>
                                <ListItemIcon sx={{
                                  color: '#1976d2'
                                }} onClick={() => history.push('/admin/abstract/dashbord')}>
                                  <DashboardIcon onClick={() => history.push('/admin/abstract/dashbord')} />

                                </ListItemIcon >

                                <ListItemText primary="Abstract Dashbord" onClick={() => history.push('/admin/abstract/dashbord')} />
                              </ListItemButton>
                              <Divider sx={{ my: 1 }} />
                              <Divider sx={{ my: 1 }} />

                              <ListItemButton onClick={handleClickAbstractArticle} sx={{
                                color: '#1976d2'
                              }}>
                                <ListItemIcon sx={{
                                  color: '#1976d2'
                                }}>
                                  <ArticleIcon />
                                </ListItemIcon>
                                {/*Sub in sub menue */}
                                <ListItemText primary="Abstracts" sx={{
                                  color: '#1976d2'
                                }} />
                                {openAbstractArticle ? <ExpandLess /> : <ExpandMore sx={{
                                  color: '#1976d2'
                                }} />}
                              </ListItemButton>
                              <Collapse in={openAbstractArticle} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                  {
                                    permissions['p_abstract-article-approve-create'] === true ?

                                      <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/abstract/add-approve-article')}>
                                        <ListItemIcon>
                                          <FactCheckIcon sx={{
                                            color: '#1976d2'
                                          }} onClick={() => history.push('/admin/abstract/add-approve-article')} />
                                        </ListItemIcon>
                                        <ListItemText primary="Add New" onClick={() => history.push('/admin/abstract/add-approve-article')} />
                                      </ListItemButton> : ""}



                                  {
                                    permissions['p_abstract-article-approve-list'] === true ?

                                      <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/abstract/approve-article')}>
                                        <ListItemIcon>
                                          <FactCheckIcon sx={{
                                            color: '#1976d2'
                                          }} onClick={() => history.push('/admin/abstract/approve-article')} />
                                        </ListItemIcon>
                                        <ListItemText primary="Approved List" onClick={() => history.push('/admin/abstract/approve-article')} />
                                      </ListItemButton> : ""}

                                  {
                                    permissions['p_abstract-article-pending-list'] === true ?

                                      <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/abstract/pending-article')}>
                                        <ListItemIcon sx={{
                                          color: '#1976d2'
                                        }}>
                                          <PendingActionsIcon onClick={() => history.push('/admin/abstract/pending-article')} />

                                        </ListItemIcon >
                                        <ListItemText primary="Pending List" onClick={() => history.push('/admin/abstract/pending-article')} />
                                      </ListItemButton> : ""}
                                  {
                                    permissions['p_abstract-article-reject-list'] === true ?
                                      <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/abstract/reject-article')}>
                                        <ListItemIcon sx={{
                                          color: '#1976d2'
                                        }}>
                                          <SwipeLeftIcon onClick={() => history.push('/admin/abstract/reject-article')} />
                                        </ListItemIcon>
                                        <ListItemText primary="Reject List" onClick={() => history.push('/admin/abstract/reject-article')} />
                                      </ListItemButton> : ""}
                                  <Divider sx={{ my: 1 }} />
                                </List>
                              </Collapse>
                              <Divider sx={{ my: 1 }} />
                              {
                                permissions['p_abstract-article-categoy-list'] === true ?

                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <FeedIcon onClick={() => history.push('/admin/abstract/all-category')} />
                                    </ListItemIcon>
                                    <ListItemText primary="Abstract Category" onClick={() => history.push('/admin/abstract/all-category')} />
                                  </ListItemButton> : ""}

                              {
                                permissions['p_abstract-article-categoy-list'] === true ?

                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <FeedIcon onClick={() => history.push('/admin/abstract/submission')} />
                                    </ListItemIcon>
                                    <ListItemText primary="Submission Type" onClick={() => history.push('/admin/abstract/submission')} />
                                  </ListItemButton> : ""}



                              {
                                permissions['p_abstract-article-comments-list'] === true ?
                                  <ListItemButton sx={{ pl: 4 }} >
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <CommentIcon onClick={() => history.push('/admin/comments/comments')} />
                                    </ListItemIcon>
                                    <ListItemText primary="Comments" onClick={() => history.push('/admin/comments/comments')} />
                                  </ListItemButton> : ""}

                              {
                                permissions['p_abstract-article-requirements-list'] === true ?
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <FolderSharedIcon onClick={() => history.push('/admin/requirements/requirements')} />
                                    </ListItemIcon>
                                    <ListItemText primary="Requirements" onClick={() => history.push('/admin/requirements/requirements')} />
                                  </ListItemButton> : ""}

                              {
                                permissions['p_abstract-article-reviewer-list'] === true ?
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <RateReviewIcon onClick={() => history.push('/admin/reviewer/reviewer-list')} />
                                    </ListItemIcon >
                                    <ListItemText primary="Reviewer" onClick={() => history.push('/admin/reviewer/reviewer-list')} />
                                  </ListItemButton> : ""}

                              {
                                permissions['p_abstract-article-author-list'] === true ?
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <SupervisedUserCircleIcon onClick={() => history.push('/admin/author/author-list')} />
                                    </ListItemIcon >
                                    <ListItemText primary="Authors" onClick={() => history.push('/admin/author/author-list')} />
                                  </ListItemButton> : ""}

                              {role === "Author" ? ""
                                :
                                notificationRead == 0 ?
                                  <ListItemButton sx={{ pl: 2 }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>

                                      <Badge badgeContent={"0"}>
                                        <NotificationsActiveIcon />
                                      </Badge>
                                    </ListItemIcon>
                                    <ListItemText primary="Notifications" />
                                  </ListItemButton> :
                                  <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <NotificationsActiveIcon onClick={() => history.push('/admin/notifictions/notifictions')} />
                                    </ListItemIcon>
                                    <ListItemText primary="Notifications" onClick={() => history.push('/admin/notifictions/notifictions')} />
                                  </ListItemButton>}

                              {/* {
                    permissions['p_abstract-article-instruction-list'] === true ?
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <InfoIcon onClick={() => history.push('/admin/instruction/instruction')} />
                        </ListItemIcon>
                        <ListItemText primary="Instructions" onClick={() => history.push('/admin/instruction/instruction')} />
                      </ListItemButton> : ""} */}
                            </List>}
                          {currentPath === '/admin/events' ? '' :
                            <ListItemButton onClick={handleClickAbstractSubMenu} sx={{
                              color: '#1976d2'
                            }}>
                              <ListItemIcon sx={{
                                color: '#1976d2'
                              }}>
                                <AppSettingsAltIcon />
                              </ListItemIcon>
                              {/*Sub in sub menue */}
                              <ListItemText primary="Setting" sx={{
                                color: '#1976d2'
                              }} />
                              {openAbstractSubMenu ? <ExpandLess /> : <ExpandMore sx={{
                                color: '#1976d2'
                              }} />}
                            </ListItemButton>}
                        </Collapse>

                        {currentPath === '/admin/events' ? '' :


                          <Collapse in={openAbstractSubMenu} timeout="auto" unmountOnExit>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon sx={{
                                color: '#1976d2'
                              }}>
                                <ContactPageIcon onClick={() => history.push('/admin/profile/profile')} />
                              </ListItemIcon>
                              <ListItemText primary="Profile" onClick={() => history.push('/admin/profile/profile')} />
                            </ListItemButton>
                            {
                              permissions['p_abstract-add-questions'] === true ?

                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <ContactPageIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Abstract Score" onClick={() => history.push('/admin/questions/answers')} />
                                </ListItemButton> : ""}
                            {/* {
                              permissions['p_event-smtp-setting'] === true ?

                                < ListItemButton sx={{ pl: 4 }}>
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <BadgeIcon />
                                  </ListItemIcon >
                                  <ListItemText primary="Email Setting" onClick={() => history.push('/admin/email/setting')} />
                                </ListItemButton> : ""} */}

                            {
                              permissions['p_abstract-article-setting'] === true ?
                                <List component="div" disablePadding>


                                  {/* <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <LocalPostOfficeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Email Template" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <LoyaltyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Couponse" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <AddCardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Payment Gateways" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <AutoStoriesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pages" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <BadgeIcon />
                        </ListItemIcon >
                        <ListItemText primary="Organiser Details" />
                      </ListItemButton> */}
                                </List> : ""}
                          </Collapse>

                        }
                      </Box>
                    </div>
                  )

                }



              })
            }




            {/* End Abstract MS   */}

            <Divider sx={{ my: 1 }} />
            {/* Start Exhibition  MS   */}
            {
              module.map((item, index) => {
                if (item.module === "EMS") {
                  return (
                    <div key={item.id}>
                      {currentPath === '/admin/events' ? '' :

                        <ListItemButton onClick={handleClickExhibitionMenu} sx={{
                          color: '#1976d2'
                        }}>
                          <ListItemIcon sx={{
                            color: '#1976d2'
                          }} >
                            <DashboardIcon onClick={() => history.push('/admin/abstract/dashbord')} />
                          </ListItemIcon >
                          <ListItemText primary="Exhibition MS" sx={{
                            color: '#1976d2'
                          }} />
                          {openExhibitionMenu ? <ExpandLess /> : <ExpandMore sx={{
                            color: '#1976d2'
                          }} />}
                        </ListItemButton>
                      }

                      <Collapse in={openExhibitionMenu} timeout="auto" unmountOnExit >
                        {currentPath === '/admin/events' ? '' :
                          <List component="div" disablePadding>
                            {
                              selfprint == "Self Printing" ? "" :
                                <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/exhibition/dashbord')}>
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }} onClick={() => history.push('/admin/exhibition/dashbord')}>
                                    <DashboardIcon onClick={() => history.push('/admin/exhibition/dashbord')} />

                                  </ListItemIcon >

                                  <ListItemText primary="Exhibition Dashbord" onClick={() => history.push('/admin/exhibition/dashbord')} />
                                </ListItemButton>
                            }

                            <Divider sx={{ my: 1 }} />
                            <Divider sx={{ my: 1 }} />

                            {/* Start  sub menue */}
                            {
                              permissions['p_exhibition-booth-categoy-list'] === true ?

                                <ListItemButton onClick={handleClickExhibitionBooth} sx={{
                                  color: '#1976d2'
                                }}>
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <ArticleIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Booth" sx={{
                                    color: '#1976d2'
                                  }} />
                                  {openExhibitionBooth ? <ExpandLess /> : <ExpandMore sx={{
                                    color: '#1976d2'
                                  }} />}
                                </ListItemButton> :
                                permissions['p_exhibition-booth-list'] === true ?
                                  <ListItemButton onClick={handleClickExhibitionBooth} sx={{
                                    color: '#1976d2'
                                  }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <ArticleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Booth" sx={{
                                      color: '#1976d2'
                                    }} />
                                    {openExhibitionBooth ? <ExpandLess /> : <ExpandMore sx={{
                                      color: '#1976d2'
                                    }} />}
                                  </ListItemButton> : ""
                            }

                            <Collapse in={openExhibitionBooth} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                {
                                  permissions['p_exhibition-booth-categoy-list'] === true ?
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/exhibition/boothcategory')}>
                                      <ListItemIcon sx={{
                                        color: '#1976d2'
                                      }}>
                                        <SwipeLeftIcon onClick={() => history.push('/admin/exhibition/boothcategory')} />
                                      </ListItemIcon>
                                      <ListItemText primary="BoothCategory" onClick={() => history.push('/admin/exhibition/boothcategory')} />
                                    </ListItemButton> : ""
                                }
                                {
                                  permissions['p_exhibition-booth-list'] === true ?
                                    <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/exhibition/booth')}>
                                      <ListItemIcon sx={{
                                        color: '#1976d2'
                                      }}>
                                        <SwipeLeftIcon onClick={() => history.push('/admin/exhibition/booth')} />
                                      </ListItemIcon>
                                      <ListItemText primary="Booth" onClick={() => history.push('/admin/exhibition/booth')} />
                                    </ListItemButton> : ""
                                }
                                <Divider sx={{ my: 1 }} />
                              </List>
                            </Collapse>
                            {/*End in sub menue */}

                            <Divider sx={{ my: 1 }} />


                            {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon sx={{
                                color: '#1976d2'
                              }}>
                                <FeedIcon />
                              </ListItemIcon>
                              <ListItemText primary="Exhibition" />
                            </ListItemButton> */}

                            {permissions['p_exhibition-booking-list'] == true ?
                              <ListItemButton sx={{ pl: 4 }} onClick={() => history.push('/admin/exhibition/booking')}>
                                <ListItemIcon sx={{
                                  color: '#1976d2'
                                }}>
                                  <CommentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Booking" onClick={() => history.push('/admin/exhibition/booking')} />
                              </ListItemButton>
                              : ""

                            }


                            <Divider sx={{ my: 1 }} />


                            {/* Start  sub menue */}
                            {
                              permissions['p_exhibition-exhibitor-categoy-list'] == true ?
                                <ListItemButton onClick={handleClickExhibitionExhibitor} sx={{
                                  color: '#1976d2'
                                }}>
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <ArticleIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Exhibitors" sx={{
                                    color: '#1976d2'
                                  }} />
                                  {openExhibitionExhibitor ? <ExpandLess /> : <ExpandMore sx={{
                                    color: '#1976d2'
                                  }} />}
                                </ListItemButton> :
                                permissions['p_exhibition-exhibitor-list'] == true ?
                                  <ListItemButton onClick={handleClickExhibitionExhibitor} sx={{
                                    color: '#1976d2'
                                  }}>
                                    <ListItemIcon sx={{
                                      color: '#1976d2'
                                    }}>
                                      <ArticleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Exhibitors" sx={{
                                      color: '#1976d2'
                                    }} />
                                    {openExhibitionExhibitor ? <ExpandLess /> : <ExpandMore sx={{
                                      color: '#1976d2'
                                    }} />}
                                  </ListItemButton> : ""


                            }

                            <Collapse in={openExhibitionExhibitor} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                {
                                  permissions['p_exhibition-exhibitor-categoy-list'] == true ?
                                    <ListItemButton sx={{ pl: 4 }} >
                                      <ListItemIcon sx={{
                                        color: '#1976d2'
                                      }}>
                                        <SwipeLeftIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Exhibitor Category " onClick={() => history.push('/admin/exhibition/exhibitor')} />
                                    </ListItemButton> : ""
                                }
                                {
                                  permissions['p_exhibition-exhibitor-list'] == true ?
                                    <ListItemButton sx={{ pl: 4 }} >
                                      <ListItemIcon sx={{
                                        color: '#1976d2'
                                      }}>
                                        <SwipeLeftIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Exhibitor" onClick={() => history.push('/admin/exhibition/allexhibitor')} />
                                    </ListItemButton>
                                    : ""
                                }

                                <Divider sx={{ my: 1 }} />
                              </List>
                            </Collapse>
                            {/*End in sub menue */}
                            {/* <Divider sx={{ my: 1 }} />

                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemIcon sx={{
                                color: '#1976d2'
                              }}>
                                <FolderSharedIcon />
                              </ListItemIcon>
                              <ListItemText primary="Event" />
                            </ListItemButton>

                            <Divider sx={{ my: 1 }} />
                            <Divider sx={{ my: 1 }} /> */}

                            {/* Start  sub menue */}
                            {/* <ListItemButton onClick={handleClickExhibitionItemService} sx={{
                              color: '#1976d2'
                            }}>
                              <ListItemIcon sx={{
                                color: '#1976d2'
                              }}>
                                <ArticleIcon />
                              </ListItemIcon>
                              <ListItemText primary="Item Service" sx={{
                                color: '#1976d2'
                              }} />
                              {openExhibitionItemService ? <ExpandLess /> : <ExpandMore sx={{
                                color: '#1976d2'
                              }} />}
                            </ListItemButton>
                            <Collapse in={openExhibitionItemService} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>

                                <ListItemButton sx={{ pl: 4 }} >
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <SwipeLeftIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Item  Category" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 4 }} >
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <SwipeLeftIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="New Item Service" />
                                </ListItemButton>
                                <Divider sx={{ my: 1 }} />
                              </List>
                            </Collapse> */}
                            {/*End in sub menue */}

                            <Divider sx={{ my: 1 }} />
                          </List>}
                        {
                          permissions['p_exhibition-visitor-list'] === true ?
                            <ListItemButton >
                              <ListItemIcon sx={{
                                color: '#1976d2',
                              }}
                                primary="Visitor's" onClick={() => history.push('/admin/exhibition/visitor')}>
                                <GroupAddIcon onClick={() => history.push('/admin/exhibition/visiter')} />

                              </ListItemIcon>

                              <ListItemText primary="Visitor's" onClick={() => history.push('/admin/exhibition/visitor')} />

                            </ListItemButton>
                            : ""}

                        {
                          permissions['p_exhibition-register-guest-list'] === true ?
                            <ListItemButton >
                              <ListItemIcon sx={{
                                color: '#1976d2',
                              }}
                                primary="Guest" onClick={() => history.push('/admin/exhibition/guest')}>
                                <GroupAddIcon onClick={() => history.push('/admin/exhibition/guest')} />

                              </ListItemIcon>

                              <ListItemText primary="Guest's" onClick={() => history.push('/admin/exhibition/guest')} />

                            </ListItemButton>
                            : ""}
                        {currentPath === '/admin/events' ? '' :
                          permissions['p_exhibition-self-scan-qr-code-list'] === true ?
                            <ListItemButton >
                              <ListItemIcon sx={{
                                color: '#1976d2',
                              }}
                                primary="QR Code Scan " onClick={() => history.push('/admin/exhibitor/scan')}>
                                <GroupAddIcon onClick={() => history.push('/admin/exhibitor/scant')} />

                              </ListItemIcon>

                              <ListItemText primary="QR Code Scan " onClick={() => history.push('/admin/exhibitor/scan')} />

                            </ListItemButton>
                            : ""}



                        {/* <ListItemButton >
                          <ListItemIcon sx={{
                            color: '#1976d2',
                          }}
                          primary="Guest" onClick={() => history.push('/admin/exhibition/pdf')}>
                          <GroupAddIcon onClick={() => history.push('/admin/exhibition/visiter')}/>
                              
                          </ListItemIcon>
                       
                            <ListItemText primary="PDF Show" onClick={() => history.push('/admin/exhibition/pdf')} />
                           
                        </ListItemButton> */}



                        {/* Start  sub menue */}
                        {
                          permissions['p_exhibition-product-and-services-list'] == true ?
                            <ListItemButton onClick={handleClickExhibitionGuestFrom} sx={{
                              color: '#1976d2'
                            }}>
                              <ListItemIcon sx={{
                                color: '#1976d2'
                              }}>
                                <ArticleIcon />
                              </ListItemIcon>
                              <ListItemText primary="Guest From " sx={{
                                color: '#1976d2'
                              }} />
                              {openExhibitionGuestFrom ? <ExpandLess /> : <ExpandMore sx={{
                                color: '#1976d2'
                              }} />}
                            </ListItemButton> :
                            permissions['p_exhibition-nature-of-business-list'] == true ?
                              <ListItemButton onClick={handleClickExhibitionGuestFrom} sx={{
                                color: '#1976d2'
                              }}>
                                <ListItemIcon sx={{
                                  color: '#1976d2'
                                }}>
                                  <ArticleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Guest From " sx={{
                                  color: '#1976d2'
                                }} />
                                {openExhibitionGuestFrom ? <ExpandLess /> : <ExpandMore sx={{
                                  color: '#1976d2'
                                }} />}
                              </ListItemButton> :
                              ""}

                        <Collapse in={openExhibitionGuestFrom} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {
                              permissions['p_exhibition-product-and-services-list'] == true ?
                                <ListItemButton sx={{ pl: 4 }} >
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <SwipeLeftIcon />
                                  </ListItemIcon>

                                  <ListItemText primary="Products and services" onClick={() => history.push('/admin/exhibition/index-services')} />
                                </ListItemButton> : ""
                            }

                            {
                              permissions['p_exhibition-nature-of-business-list'] == true ?
                                <ListItemButton sx={{ pl: 4 }} >
                                  <ListItemIcon sx={{
                                    color: '#1976d2'
                                  }}>
                                    <SwipeLeftIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Nature of business" onClick={() => history.push('/admin/exhibition/index-nature')} />
                                </ListItemButton>
                                : ""
                            }

                            <Divider sx={{ my: 1 }} />
                          </List>
                        </Collapse>
                        {/*End in sub menue */}




                        {currentPath === '/admin/events' ? '' :

                          selfprint == "Self Printing" ? "" :
                            <ListItemButton onClick={handleClickExhibitionSetting} sx={{
                              color: '#1976d2'
                            }}>
                              <ListItemIcon sx={{
                                color: '#1976d2'
                              }}>
                                <AppSettingsAltIcon />
                              </ListItemIcon>
                              {/*Sub in sub menue */}
                              <ListItemText primary="Setting" sx={{
                                color: '#1976d2'
                              }} />
                              {openExhibitionSetting ? <ExpandLess /> : <ExpandMore sx={{
                                color: '#1976d2'
                              }} />}
                            </ListItemButton>

                        }
                      </Collapse>

                      {currentPath === '/admin/events' ? '' :
                        <Collapse in={openExhibitionSetting} timeout="auto" unmountOnExit>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon sx={{
                              color: '#1976d2'
                            }}>
                              <ContactPageIcon onClick={() => history.push('/admin/exhibition/exhibitor-profile')} />
                            </ListItemIcon>
                            <ListItemText primary="Profile" onClick={() => history.push('/admin/exhibition/exhibitor-profile')} />
                          </ListItemButton>
                          {
                            permissions['p_exhibition-guest-badge-design-list'] == true ?
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon sx={{
                                  color: '#1976d2'
                                }}>
                                  <ContactPageIcon onClick={() => history.push('/admin/exhibition/badge')} />
                                </ListItemIcon>
                                <ListItemText primary="Guest Badge Design" onClick={() => history.push('/admin/exhibition/badge')} />
                              </ListItemButton> : ""}
                          <List component="div" disablePadding>
                            {/* <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <LocalPostOfficeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Email Template" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <LoyaltyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Couponse" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <AddCardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Payment Gateways" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <AutoStoriesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pages" />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{
                          color: '#1976d2'
                        }}>
                          <BadgeIcon />
                        </ListItemIcon >
                        <ListItemText primary="Organiser Details" />
                      </ListItemButton> */}
                          </List>
                        </Collapse>

                      }

                    </div>

                  )
                }


              })
            }


            <Divider sx={{ my: 1 }} />
            {/* End Exhibition  MS   */}






            <Divider sx={{ my: 1 }} />
            {/* End PMS Work Shop  MS   */}



          </List>
        </Drawer>
      </Box>

    </div >

  )
}

export default NavBar
